<template>
  <div class="h-full cust_card p-2 mt-2 w-full">
    <div class="heading-3 text-text1" :class="mobileView ? 'heading-5' : 'heading-3'">● {{$route.params.templateId > 0 ? 'Edit Template' : 'Create Template'}}:</div>
    <div class="grid lg:grid-cols-2 gap-4 mb-4 mt-3">
      <div class="">
        <div class=" bg-white cust_card p-4">
          <div>
            <div>
              <div class="pb-2">
                <TextField
                  :inputId="'planButTitle1231'"
                  :showcharLimit="false"
                  :inputext="reqObj.templateName"
                  :placholderText="`Template Name`"
                  :lableText="'Template Name'"
                  :autoFocus="false"
                  :fieldError="nameErr !== ''"
                  @keyPressAction="nameErr = ''"
                  @inputChangeAction="(data) => reqObj.templateName = data" />
                  <p class="text-error heading-7">{{nameErr}}</p>
              </div>
            </div>
              <div class="py-2">
                <p class="label_css flex"><span>Text Template</span> </p>
                  <textarea ref="messageInput" id="messageInputBox"
                  maxlength="10000"
                  cols="100"
                  rows="8"
                  placeholder="Text Template"
                  @keypress="msgErr = ''"
                  class="cust_text_field py-4  px-2 w-full  m-px flex text-text1 bg-white rounded items-center"
                  :class="`${msgErr !== '' ? 'ring-1 ring-error' : 'border border-gray2  flex text-text1 focus:border-primary focus:ring-1'}`"
                  autocomplete="off"
                  v-model="reqObj.templateMessage"
                ></textarea>
                <p class="text-error heading-7">{{msgErr}}</p>
              </div>
              <div class="mb-1 border border-gray2 rounded" v-if="mobileView">
                <div class="label_css pl-2">Please click tags shown below to add in template</div>
                <div class="mb-1  grid lg:grid-cols-3 md:grid-cols-2">
                    <div v-for="(data, II) in entityMergeFieldList" :key="II" class="heading-6  ml-2 text-text2">
                      <span><span class="text-primary cursor-pointer hover:border-gray4 border p-0.5 border-transparent transition rounded-md" @click="addTags(data.fieldSlug, II)">• {{ data.displayName }}</span></span>
                    </div>  
                </div>
              </div>
              <div class="flex items-center justify-between">
                <div class="flex items-center py-2">
                  <label class="cu_switch cursor-pointer mr-3" :class="!reqObj.isTemplateActive ? 'ml-2' : ''">
                    <input type="checkbox" v-model="reqObj.isTemplateActive" @click="changeButtpn2()">
                    <span class="cu_slider cu_round"></span>
                  </label>
                  <p class="text-gray3">{{reqObj.isTemplateActive ? 'Template is Active' : `Template is Inactive`}}</p>
                </div>
                <div class="text-right">
                  <!-- <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'error'" :btnText="'Delete Template'" @buttonAction="dleteteTemp=true"/> -->
                </div>
              </div>
          </div>
        </div>
      </div>
      <div class="bg-white p-4 cust_card" v-if="!mobileView">
          <div class="py-1 text-text1 heading-3">
            Please click tags shown below to add in template
          </div>
          <hr class="bottom_space">
          <div class="grid xl:grid-cols-2" wrap>
            <div v-for="(data, index) in entityMergeFieldList" :key="index" class="pt-0">
              <div class="pt-2">
                <span><span class="text-primary cursor-pointer hover:border-gray4 border p-0.5 border-transparent transition rounded-md" @click="addTags(data.fieldSlug, index)">• {{ data.displayName }}</span></span>
              </div>
            </div>
          </div>
        </div>
    </div>
     <div class="" :class="mobileView ? 'fixed bottom-0 left-0 w-full' : 'pb-4'">
        <div class="bg-white text-right rounded p-2 cust_card">
          <div class="flex justify-end gap-2">
            <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Discard'" @buttonAction="canceladdTemplate"/>
            <Button v-if="$route.params.templateId > 0" :btnSize="'medium'" :textColor="'white'" :btnColor="'error'" :btnText="'Delete Template'" @buttonAction="dleteteTemp=true"/>
            <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Save'" @buttonAction="addNewEmailTemplate"/>
          </div>
        </div>
     </div>
     <div v-if="dleteteTemp" class="popup_overlay relative px-4">
      <div style="width: 500px;" class="custom_dialog rounded-xl h-min p-4">
        <div  class="mb-5 font-bold">
          <p class="heading-3 text-text1">Are you sure?</p>
        </div>
        <div  class="mb-2 font-bold">
          <p class="heading-6 text-text1">Once deleted cannot undone.</p>
        </div>
        <div class="flex gap-2 justify-end">
          <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="dleteteTemp = false"/>
          <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'error'" :btnText="'Delete'" @buttonAction="deleteTemplate"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Button from '@/View/components/globalButton.vue'
import TextField from '@/View/components/textfield.vue'
import ADMINAPI from '@/View/Admin/api/Admin.js'
export default {
  components: {
    TextField,
    Button
  },
  data () {
    return {
      dleteteTemp: false,
      mobileView: false,
      contentHeight: 0,
      entityMergeFieldList: [],
      nameErr: '',
      msgErr: '',
      reqObj:{
        templateId: 0,
        templateName: '',
        templateMessage: '',
        isTemplateActive: true
      }
    }
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeWindowHandler);
  },
  watch: {
    reqObj: {
      handler() {
        if (this.reqObj.templateName !== '') {
          this.nameErr = ''
        }
        if (this.reqObj.templateMessage !== '') {
          this.msgErr = ''
        }
      },
      deep: true
    },
  },
  mounted () {
    if (this.$route.params.templateId > 0) {
      console.log('11')
      this.getEmailTemplateDetails()
      document.title = 'Edit Templates'
    } else {
      console.log('22')
      document.title = 'Add Templates'
    }
    this.addTemplateRequiredDetails()
    this.resizeWindowHandler();
  },
  methods: {
    deleteTemplate () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading.....'})
      ADMINAPI.SmsTemplateDelete(
        this.$route.params.templateId,
        response => {
          this.$router.push({name: 'Template'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    addTemplateRequiredDetails () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading.....'})
      ADMINAPI.GetPrefrenceTag(
        response => {
          this.entityMergeFieldList = response.Data !== null ? response.Data : []
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    addTags(addValue) {
      let el = document.getElementById(`messageInputBox`);
      let strval = el.value;
      let start = el.selectionStart;
      let end = el.selectionEnd;
      this.msgErr = '';
      this.reqObj.templateMessage = strval.slice(0, start) + addValue + ' ' + strval.slice(end);
      let cursorPos = start + addValue.length + 1; // Move cursor position after the added space
      setTimeout(() => {
        el.focus();
        el.setSelectionRange(cursorPos, cursorPos);
      }, 0);
    },
    addNewEmailTemplate () {
      let errorFound = false
      if (this.reqObj.templateName.trim() === '') {
        this.nameErr = (this.reqObj.templateName.trim() === '') ? 'Template Name is Required' : ''
        errorFound = true
      }
      if (this.reqObj.templateMessage.trim() === '') {
        this.msgErr = (this.reqObj.templateMessage.trim() === '') ? 'Text Template is required' : ''
        errorFound = true
      }
      if (errorFound !== true) {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading.....'})
        ADMINAPI.SmsTemplateAddEdit(
          this.reqObj,
          () => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            // this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$router.push({name: 'Template'})
          },
          error => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      }
    },
    getEmailTemplateDetails () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading.....'})
      ADMINAPI.SmsTemplateDetail(
        this.$route.params.templateId,
        response => {
          let etDetails = response.Data
          this.reqObj = {
            templateId: etDetails.templateId,
            templateMessage: etDetails.templateMessage,
            templateName: etDetails.templateName,
            isTemplateActive: etDetails.isTemplateActive,
          },
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading.....'})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    canceladdTemplate () {
      this.$router.push({name: 'Template'})
    },
    resizeWindowHandler() {
      this.contentHeight = window.innerHeight - 210
      if (window.innerWidth < 1024) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
    }
  }
}
</script>
